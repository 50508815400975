import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isNil } from 'lodash';
import { useTranslations } from 'next-intl';

import { useDebouncedValue } from 'hooks';
import { RestrictionType } from 'types/gql.bet';
import { setMaxStakeError } from '../betslipActions';
import { betslipVars } from '../betslipState';
import { ErrorCodeMessages } from '../constants';
import useGetMaxStake from './useGetMaxStake';

const useMaxStakeError = (): void => {
    const t = useTranslations('betting-betslip');

    const { stake } = useReactiveVar(betslipVars);
    const { maxStake } = useGetMaxStake();

    const debouncedMaxBet = useDebouncedValue({ value: maxStake });

    useEffect(() => {
        if (isNil(debouncedMaxBet)) return;

        if (debouncedMaxBet === 0 || Number(debouncedMaxBet) < Number(stake)) {
            setMaxStakeError({
                message: t(ErrorCodeMessages[RestrictionType.MaxBet]),
                type: RestrictionType.MaxBet,
            });

            return;
        }

        setMaxStakeError(null);
    }, [stake, t, debouncedMaxBet]);
};

export default useMaxStakeError;
