import { useMemo } from 'react';

import { useGetRestrictions } from 'gql/betting/queries/getRestrictions.bet';
import { MaxBetRestriction } from 'types/gql.bet';
import { RestrictionsTypename } from '../constants';

interface Output {
    maxStake?: number;
}

const useGetMaxStake = (): Output => {
    const { data: restrictionsData } = useGetRestrictions(() => ({
        fetchPolicy: 'cache-only',
    }));

    const maxStake = useMemo(
        () =>
            restrictionsData?.restrictions.find(
                (restriction): restriction is MaxBetRestriction =>
                    restriction.__typename ===
                    RestrictionsTypename.MaxBetRestriction
            )?.maxBet,
        [restrictionsData?.restrictions]
    );

    return { maxStake };
};

export default useGetMaxStake;
