import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { floor } from 'lodash';
import { useTranslations } from 'next-intl';

import { setMinStakeError } from '../betslipActions';
import { betslipVars } from '../betslipState';
import { PRECISION_FOR_AMOUNTS } from '../constants';

const useMinStakeError = (): void => {
    const t = useTranslations('betting-betslip');

    const { stake, minBet } = useReactiveVar(betslipVars);

    useEffect(() => {
        if (!minBet || !stake || +stake >= minBet) {
            setMinStakeError(null);

            return;
        }

        setMinStakeError({
            message: getStakeMinimalStakeIsError(minBet, t),
        });
    }, [minBet, stake, t]);
};

function getStakeMinimalStakeIsError(
    minBet: number,
    t: ReturnType<typeof useTranslations>
): string {
    return `${t('betslip.Odd.Error.stakeMinimalStakeIs')} ${floor(
        minBet,
        PRECISION_FOR_AMOUNTS
    )}`;
}

export default useMinStakeError;
