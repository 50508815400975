import { useState } from 'react';
import type { SubscriptionHookOptions } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';

import type { OnUpdateBetHookResult } from 'gql/betting/subscriptions/onUpdateBet.bet';
import { useOnUpdateBet } from 'gql/betting/subscriptions/onUpdateBet.bet';
import { useAuthorization } from 'hooks';
import type { OnUpdateBet } from 'types/gql.bet';
import { clearOdds, setStatus } from '../betslipActions';
import { betslipVars } from '../betslipState';
import { BetslipStatus, SUCCESS_DELAY } from '../constants';
import { convertBetStatus } from '../utils';

type Input = Pick<
    SubscriptionHookOptions<OnUpdateBet>,
    'onData' | 'fetchPolicy'
> & {
    onSuccess?: VoidFunction;
    onError?: VoidFunction;
};

const useOnBetslipBet = ({
    onData,
    onSuccess,
    onError,
    fetchPolicy = 'network-only',
}: Input): OnUpdateBetHookResult => {
    const { isAuthorized } = useAuthorization();

    const oddState = useReactiveVar(betslipVars);
    const [data, setData] = useState<OnUpdateBetHookResult>({
        data: undefined,
        error: undefined,
        loading: false,
    });

    useOnUpdateBet({
        onData: (response) => {
            const betData = response.data?.data?.onUpdateBet;

            if (!betData || betData.id !== oddState.betId) return;

            setData(response.data);

            const betStatus = convertBetStatus(betData.status);

            const isSuccess = betStatus === BetslipStatus.Success;
            const isError = betStatus === BetslipStatus.Error;

            if (onData) onData(response);

            if (isSuccess) {
                setTimeout(() => {
                    clearOdds();

                    if (onSuccess) onSuccess();
                }, SUCCESS_DELAY);

                return;
            }

            if (isError && onError) {
                onError();
            }

            setStatus(betStatus);
        },
        fetchPolicy,
        skip: !isAuthorized,
    });

    return data;
};

export default useOnBetslipBet;
