import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { first, floor, last, nth } from 'lodash';

import { useAuthorization } from 'hooks';
import {
    BetSortFields,
    BetStatus,
    BetType,
    GetBetSuggest,
    SortDirection,
} from 'types/gql.bet';
import { saveStake } from '../betslipActions';
import { useGetBetsStake } from '../BetslipFooter/getBetsStake.bet';
import { activeBetslipFreebetIdVar, betslipVars } from '../betslipState';
import { PRECISION_FOR_AMOUNTS } from '../constants';

interface Input {
    suggests: GetBetSuggest['betStakeSuggest'];
}

const useAutofillAmount = ({ suggests }: Input): void => {
    const { isAuthorized } = useAuthorization();
    const { mode, oddIds } = useReactiveVar(betslipVars);
    const activeFreebetId = useReactiveVar(activeBetslipFreebetIdVar);
    const isActiveFreebet = mode !== BetType.System && Boolean(activeFreebetId);

    const {
        data: lastBet,
        error,
        loading,
    } = useGetBetsStake(() => ({
        variables: {
            input: {
                limit: 1,
                offset: 0,
                statuses: [
                    BetStatus.Accepted,
                    BetStatus.RolledBack,
                    BetStatus.Settled,
                    BetStatus.Unsettled,
                ],
                sort: {
                    field: BetSortFields.CreatedAt,
                    direction: SortDirection.Desc,
                },
            },
        },
        fetchPolicy: 'network-only',
        skip: !isAuthorized || isActiveFreebet,
    }));

    const lastBetStake = Number(lastBet?.bets?.[0]?.stake ?? 0);

    useEffect(() => {
        if (
            !isAuthorized ||
            !suggests.length ||
            !oddIds.length ||
            loading ||
            isActiveFreebet
        )
            return;

        const firstSuggest = Number(first(suggests)?.amount || 0);
        const lastSuggest = Number(last(suggests)?.amount || 0);
        const secondSuggest = Number(nth(suggests, 2)?.amount || 0);

        const lastBetValue =
            error || lastBetStake > lastSuggest || lastBetStake < firstSuggest
                ? 0
                : lastBetStake;

        saveStake((prev) => {
            switch (true) {
                case !prev: {
                    return `${floor(
                        lastBetValue || secondSuggest || lastSuggest,
                        PRECISION_FOR_AMOUNTS
                    )}`;
                }

                case Number(prev) > lastSuggest: {
                    return `${floor(lastSuggest, PRECISION_FOR_AMOUNTS)}`;
                }

                case Number(prev) < firstSuggest: {
                    return `${floor(secondSuggest, PRECISION_FOR_AMOUNTS)}`;
                }

                default: {
                    return prev;
                }
            }
        });
    }, [
        isAuthorized,
        error,
        lastBetStake,
        suggests,
        loading,
        oddIds.length,
        isActiveFreebet,
    ]);

    useEffect(() => {
        const currentSuggest = Number(
            nth(suggests, 2)?.amount || last(suggests)?.amount || 0
        );

        if (!oddIds.length) return;

        if (!isAuthorized) {
            saveStake((prev) => {
                return (
                    prev || `${floor(currentSuggest, PRECISION_FOR_AMOUNTS)}`
                );
            });
        }
    }, [isAuthorized, oddIds.length, suggests]);
};

export default useAutofillAmount;
