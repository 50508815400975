import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import { useGetRestrictions } from 'gql/betting/queries/getRestrictions.bet';
import { BetType } from 'types/api.bet';
import { setRestrictionValidationErrors } from '../betslipActions';
import { betslipVars } from '../betslipState';
import { ErrorCodeMessages, ErrorCodes } from '../constants';
import { BetslipError } from '../types';
import { getRestrictionDetails } from '../utils';

const BlacklistTypenameRestrictions = [
    'MaxBetRestriction',
    'SelectionValueRestriction',
];

const useRestrictionsError = (): void => {
    const t = useTranslations('betting-betslip');
    const { mode } = useReactiveVar(betslipVars);

    const { data: restrictionResponse } = useGetRestrictions(() => ({
        fetchPolicy: 'cache-only',
    }));

    useEffect(() => {
        const restrictions = restrictionResponse?.restrictions || [];

        if (!restrictions.length) return;

        const restrictionForState = restrictions.reduce<BetslipError[]>(
            (acc, restriction) => {
                const { restrictionType, reason } =
                    getRestrictionDetails(restriction);

                if (
                    restriction.__typename &&
                    BlacklistTypenameRestrictions.includes(
                        restriction.__typename
                    )
                ) {
                    return acc;
                }

                const err: BetslipError = {
                    type: restrictionType,
                    ...('sportEventId' in restriction
                        ? { sportEventId: restriction.sportEventId }
                        : {}),
                    message: t(
                        ErrorCodeMessages[reason || restrictionType] ||
                            getDefaultErrorMessages(mode),
                        restriction
                    ),
                };

                acc.push(err);

                return acc;
            },
            []
        );

        setRestrictionValidationErrors(restrictionForState);
    }, [mode, restrictionResponse, t]);
};

function getDefaultErrorMessages(betType: BetType): string {
    return [BetType.Express, BetType.System].includes(betType)
        ? 'betslip.GroupOfOdds.betslipOddsHasConflicts'
        : ErrorCodeMessages[ErrorCodes.InternalError];
}

export default useRestrictionsError;
