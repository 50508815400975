import { useMemo } from 'react';

import { useGetMissingMatches } from 'hooks';
import { useBettingApolloClient } from 'services/apollo';
import { Odd } from 'types/gql.bet';
import { getOddsByIds } from '../utils';

interface Input {
    oddIds: string[];
}
interface Output {
    odds: Odd[];
}

const useGetMissingOdds = ({ oddIds }: Input): Output => {
    const { client } = useBettingApolloClient();

    const { missingMatches } = useGetMissingMatches({
        oddIds,
        fetchPolicy: 'cache-only',
    });

    const odds = useMemo(() => {
        if (!missingMatches) return [];

        return getOddsByIds(oddIds, client);
    }, [oddIds, client, missingMatches]);

    return {
        odds,
    };
};

export default useGetMissingOdds;
