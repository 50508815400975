import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateSportEventByOdd.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateSportEventByOdd__
 *
 * To run a query within a React component, call `useOnUpdateSportEventByOdd` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateSportEventByOdd` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateSportEventByOdd({
 *   variables: {
 *      sportEventId: // value for 'sportEventId'
 *      marketLimit: // value for 'marketLimit'
 *      marketIds: // value for 'marketIds'
 *      marketStatuses: // value for 'marketStatuses'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useOnUpdateSportEventByOdd(
    baseOptions: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateSportEventByOdd,
        GQL.OnUpdateSportEventByOddVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateSportEventByOdd,
        GQL.OnUpdateSportEventByOddVariables
    >(Operations.OnUpdateSportEventByOdd, options);
}
export type OnUpdateSportEventByOddHookResult = ReturnType<
    typeof useOnUpdateSportEventByOdd
>;
export type OnUpdateSportEventByOddSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateSportEventByOdd>;
