import * as GQL from 'types/gql.bet';

import * as Operations from './onUpdateBet.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

/**
 * __useOnUpdateBet__
 *
 * To run a query within a React component, call `useOnUpdateBet` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateBet` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateBet({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateBet(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQL.OnUpdateBet,
        GQL.OnUpdateBetVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSubscription<
        GQL.OnUpdateBet,
        GQL.OnUpdateBetVariables
    >(Operations.OnUpdateBet, options);
}
export type OnUpdateBetHookResult = ReturnType<typeof useOnUpdateBet>;
export type OnUpdateBetSubscriptionResult =
    Apollo.SubscriptionResult<GQL.OnUpdateBet>;
