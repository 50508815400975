import { useCallback, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslations } from 'next-intl';

import { SidebarTabId } from 'app-constants';
import { usePolling } from 'hooks';
import activeSidebarTabVar from 'layouts/BettingLayout/Sidebar/SidebarTabs/sidebarTabsState';
import { useLazyQuery } from 'services/apollo/betting';
import { BetStatus } from 'types/gql.bet';
import {
    clearOdds,
    setBetErrors,
    setBetId,
    setStatus,
} from '../betslipActions';
import { betslipVars } from '../betslipState';
import {
    BetslipStatus,
    ErrorCodeMessages,
    ErrorCodes,
    GET_BETS_POLLING_INTERVAL,
    RESET_STATE_INTERVAL,
    WAIT_FOR_START_GET_BETS_POLLING,
} from '../constants';

import getBetQuery from '../BetslipFooter/getBetsStake.bet.gql';

const usePollingBets = (): void => {
    const t = useTranslations('betting-betslip');
    const { status, betId } = useReactiveVar(betslipVars);
    const startTimerRef = useRef<number | null>(null);
    const endTimerRef = useRef<number | null>(null);

    const [getBet] = useLazyQuery(getBetQuery);

    const poller = useCallback(async () => {
        getBet({
            fetchPolicy: 'no-cache',
            variables: {
                input: {
                    ids: [betId],
                    limit: 1,
                    statuses: [
                        BetStatus.Accepted,
                        BetStatus.RolledBack,
                        BetStatus.Settled,
                        BetStatus.Unsettled,
                        BetStatus.Declined,
                    ],
                },
            },
        })
            .then(({ data }) => {
                if (data?.bets?.length > 0) {
                    clearOdds();
                    setBetId('');
                    activeSidebarTabVar(SidebarTabId.BetHistory);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [betId, getBet]);

    const {
        startPolling: startPollingBetById,
        stopPolling: stopPollingBetById,
    } = usePolling({
        poller,
        interval: GET_BETS_POLLING_INTERVAL,
    });

    useEffect(() => {
        if (status === BetslipStatus.Processing) {
            endTimerRef.current = window.setTimeout(() => {
                stopPollingBetById();
                setBetId('');
                setBetErrors([
                    {
                        message: t(
                            ErrorCodeMessages[ErrorCodes.ResponseTimedOut]
                        ),
                    },
                ]);
                setStatus(BetslipStatus.Error);
            }, RESET_STATE_INTERVAL);

            return;
        }

        if (endTimerRef.current) {
            clearTimeout(endTimerRef.current);
        }
    }, [stopPollingBetById, t, status]);

    useEffect(() => {
        if (!betId) return;

        if (status === BetslipStatus.Processing) {
            startTimerRef.current = window.setTimeout(() => {
                startPollingBetById();
            }, WAIT_FOR_START_GET_BETS_POLLING);

            return;
        }

        stopPollingBetById();

        if (startTimerRef.current) {
            clearTimeout(startTimerRef.current);
        }
    }, [betId, startPollingBetById, status, stopPollingBetById, t]);

    useEffect(
        () => () => {
            if (startTimerRef.current) {
                clearTimeout(startTimerRef.current);
            }

            if (endTimerRef.current) {
                clearTimeout(endTimerRef.current);
            }
        },
        []
    );
};

export default usePollingBets;
