import type { FetchPolicy } from '@apollo/client';

import marketBase from 'gql/betting/fragments/marketBaseFragment.bet.gql';
import matchBaseFragment from 'gql/betting/fragments/matchBaseFragment.bet.gql';
import oddFragment from 'gql/betting/fragments/oddFragment.bet.gql';
import { useCheckOddIsActive, useFragment } from 'hooks';
import { useBettingApolloClient } from 'services/apollo';
import type { Market, MatchBase, Odd } from 'types/gql.bet';
import { SportEventStatus } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import { useOnUpdateSportEventByOdd } from '../onUpdateSportEventByOdd.bet';

interface Input {
    id: string;
    fetchPolicy?: FetchPolicy;
}

interface Output {
    isActiveOdd: boolean;
    sportEvent?: MatchBase;
    oddData?: Odd;
    market?: Market;
}

const useSubscriptionPerOdd = ({
    id,
    fetchPolicy = 'network-only',
}: Input): Output => {
    const { client } = useBettingApolloClient();

    const { data: oddData } = useFragment<Odd>({
        id,
        fragment: oddFragment,
        typename: 'Odd',
        client,
    });
    const { sportEventId, marketId } =
        oddData && oddData.path
            ? idPathParser.split('Odd', oddData.path)
            : { sportEventId: '', marketId: '' };

    const { data: sportEvent } = useFragment<MatchBase>({
        id: sportEventId,
        client,
        typename: 'SportEvent',
        fragment: matchBaseFragment,
        options: {
            skip: !oddData,
            returnPartialData: true,
        },
    });

    const { data: market } = useFragment<Market>({
        id: idPathParser.join('Market', {
            sportEventId: sportEvent?.id || '',
            marketId,
        }),
        client,
        typename: 'Market',
        fragment: marketBase,
        options: {
            skip: !oddData || !sportEvent,
        },
    });

    const { isActiveOdd } = useCheckOddIsActive({
        odd: oddData,
        market,
        sportEventId: sportEvent?.id || '',
    });

    const isEndedMatch =
        sportEvent &&
        [
            SportEventStatus.Ended,
            SportEventStatus.Cancelled,
            SportEventStatus.Closed,
        ].includes(sportEvent.fixture.status);

    useOnUpdateSportEventByOdd({
        variables: {
            sportEventId,
            marketIds: [marketId],
            marketLimit: 1,
        },
        skip: !sportEvent || !marketId || isEndedMatch,
        fetchPolicy,
        onComplete: () => {
            // Note: need to check when onComplete will be called
            console.info('useOnUpdateSportEventByOdd onComplete');
        },
    });

    return {
        sportEvent,
        oddData,
        market,
        isActiveOdd,
    };
};

export default useSubscriptionPerOdd;
