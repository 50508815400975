import { useEffect } from 'react';
import { first } from 'lodash';
import { useTranslations } from 'next-intl';

import { useRouterPush } from 'components/HybridRouter';
import { Feature, useFeatureFlags } from 'services/features';
import { setBetErrors } from '../betslipActions';
import {
    getDeclineReason,
    getRestrictionForState,
    redirectByRestriction,
} from '../utils';
import useOnBetslipBet from './useOnBetslipBet';

const useBetError = (): void => {
    const t = useTranslations('betting-betslip');
    const { push } = useRouterPush();

    const { data: bet } = useOnBetslipBet({ fetchPolicy: 'cache-only' });
    const { features } = useFeatureFlags();
    const isAppResponsibleGaming = features.isEnabled(
        Feature.AppResponsibleGaming
    );

    const { declineContext, declineReason = '' } = bet?.onUpdateBet || {};

    useEffect(() => {
        const restrictions = first(declineContext)?.restrictions || [];

        const restrictionForState = getRestrictionForState(restrictions, t);

        redirectByRestriction(
            restrictionForState,
            push,
            isAppResponsibleGaming
        );

        const declineReasonFallback = getDeclineReason(declineReason, t);

        setBetErrors(restrictionForState, declineReasonFallback);
    }, [declineContext, declineReason, push, t, isAppResponsibleGaming]);
};

export default useBetError;
